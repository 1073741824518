<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Profil</h1>
        <v-form v-model="valid" lazy-validation ref="form" @submit.prevent="doSubmit">
          <v-card>
            <v-card-text>
              <v-alert :type="message.type" dismissible v-model="message.show">{{ message.message }}</v-alert>
              <v-row>
                <v-col sm="9">
                  <v-text-field label="Nama" :rules="rules.name" v-model="name"></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-radio-group v-model="sex" row>
                    <v-radio label="Laki-laki" value="M"></v-radio>
                    <v-radio label="Perempuan" value="F"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col sm="8">
                  <v-text-field label="Alamat" v-model="address"></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field label="Telepon" v-model="phone"></v-text-field>
                </v-col>
              </v-row>
              <v-text-field label="Email" counter="150" :rules="rules.email" v-model="email"></v-text-field>
              <v-row>
                <v-col sm="6">
                  <v-text-field type="password" :rules="rules.password" label="Password" v-model="password"></v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field type="password" :rules="rules.passwordConfirmation" label="Konfirmasi password" v-model="passwordConfirmation"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" color="primary">Simpan</v-btn>
              <!--              <v-btn color="error" @click.prevent="message.show=true">Batalkan</v-btn>-->
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "BoProfile",
  data() {
    return {
      message: {
        show: false,
        type: 'error',
        message: null
      },
      userId: null,
      valid: true,
      name: null,
      sex: null,
      address: null,
      phone: null,
      email: null,
      password: null,
      passwordConfirmation: null,
      rules: {
        name: [v => !!v || 'Nama harus diisi.'],
        email: [
          v => !!v || 'Email harus diisi.',
          v => /.+@.+\..+/.test(v) || 'E-mail tidak valid.'
        ],
        password: [v => v ? v.length >= 8 && v.length <= 100 ? true : 'Password minimal 8 karakter, maksimal 100 karakter.' : true],
        passwordConfirmation: [v => v === this.password || 'Konfirmasi harus sama dengan password.']
      }
    }
  },
  methods: {
    async doSubmit() {
      if (this.$refs.form.validate()) {
        try {
          // console.log(this.sex);
          let response = await axios.put('/user', {
            id: this.userId,
            name: this.name,
            address: this.address,
            phone: this.phone,
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            sex: this.sex
          });
          let responseData = response.data;
          if (responseData.resultStatus) {
            this.message.show = true;
            this.message.message = responseData.resultMsg;
            this.message.type = 'success';
            this.password = null;
            this.passwordConfirmation = null;
          } else {
            console.log(responseData);
            this.message.show = true;
            this.message.message = responseData.resultMsg;
            this.message.type = 'error';
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  },
  async created() {
    try {
      let response = await axios.get('/user', {params: {mode: 'auth'}});
      let responseData = response.data;
      this.name = responseData.data.name;
      this.email = responseData.data.email;
      this.userId = responseData.data.id;
      this.address = responseData.data.address;
      this.phone = responseData.data.phone;
      this.sex = responseData.data.sex;
    } catch (error) {
      console.log(error);
    }
  }
}
</script>

<style scoped>

</style>