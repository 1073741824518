<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Home</h1>
        <p v-if="user">Hello, {{ user.name }}, your token is: {{ token }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'Home',
  computed: {
    ...mapGetters(['user', 'token'])
  }
}
</script>
