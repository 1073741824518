<template>
  <div>
    <v-alert :type="type" v-model="show" dismissible>{{ message }}</v-alert>
  </div>
</template>

<script>
export default {
  name: "MsgAlert",
  data() {
    return {
      // showMsg: true
    }
  },
  props: ["message", "type", "show"]
}
</script>

<style scoped>

</style>