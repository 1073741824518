<template>
  <v-container>
    <v-row>
      <v-col>
        <v-dialog v-model="dialog.show" persistent max-width="1280px" @keydown.esc="closeDialog" @click:outside="closeDialog">
          <v-form ref="form" lazy-validation v-model="form.valid" @submit.prevent="doSubmit">
            <v-card>
              <v-card-title>{{ dialog.title }} class batch</v-card-title>
              <v-card-text>
                <v-alert :type="form.alert.type" v-model="form.alert.show" dismissible>{{ form.alert.message }}</v-alert>
                <v-row>
                  <v-col cols="6">
                    <v-select label="Tipe kelas" :items="types" item-text="text" item-value="value" v-model="type" :rules="form.rules.type"></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select label="Class group" :rules="form.rules.classGroup" :items="classGroups" item-text="name" item-value="id" v-model="classGroup"></v-select>
                  </v-col>
                </v-row>
                <v-select label="Instruktur" :rules="form.rules.teacher" :items="teachers" item-text="name" item-value="id" v-model="teacher"></v-select>
                <v-row dense>
                  <v-col cols="12" sm="9">
                    <v-text-field label="Nama" v-model="name" :rules="form.rules.name" counter="100"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-datetime-picker label="Registrasi dibuka" v-model="registration_start"></v-datetime-picker>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-datetime-picker label="Registrasi ditutup" v-model="registration_end"></v-datetime-picker>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-datetime-picker label="Kelas dimulai" v-model="batch_start"></v-datetime-picker>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-datetime-picker label="Kelas diakhiri" v-model="batch_end"></v-datetime-picker>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-text-field label="Jumlah siswa minimal" v-model="student_min" :rules="form.rules.student_min"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field label="Jumlah siswa maksimal" v-model="student_max" :rules="form.rules.student_max"></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field label="Harga" v-model="price" :rules="form.rules.price"></v-text-field>
                <v-select label="Status" :items="statuses" item-text="text" item-value="value" v-model="status"></v-select>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit">Simpan</v-btn>
                <v-btn color="error" @click="closeDialog">Batalkan</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <v-dialog v-model="dialog2.show" persistent max-width="600px" @keydown.esc="closeDialog2" @click:outside="closeDialog2">
          <v-card>
            <v-card-title>Hapus class batch</v-card-title>
            <v-card-text>
              <p>Apakah Anda yakin ingin menghapus batch class dengan nama: <strong>{{ name }}</strong>?</p>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="doDelete(uId)">Ya</v-btn>
              <v-btn color="success" @click="closeDialog2">Tidak</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <h1>Class batch</h1>
        <v-alert :type="alert.type" dismissible v-model="alert.show">{{ alert.message }}</v-alert>
        <p>
          <v-btn color="success" @click.prevent="openDialog">Tambah</v-btn>
        </p>
        <v-card v-if="!detail.show">
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Cari" single-line hide-details @keydown.esc="search=null"></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-data-table :search="search" :items="cbs" :headers="dt.headers" :items-per-page="10">
              <template v-slot:item.status="{item}">
                <v-chip label :color="item.status">{{ activeStatus(1, item.status) }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="viewItem(item)">mdi-magnify</v-icon>
                <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                <v-icon small @click="deleteItem(item)" color="error">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <!--        <v-row v-model="detail.show">-->
        <!--          <v-col>-->
        <!--            <h2>{{ name }}</h2>-->
        <!--            <v-row>-->
        <!--              <v-col>-->

        <!--              </v-col>-->
        <!--            </v-row>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import DatetimePicker from "vuetify-datetime-picker";

export default {
  name: "BoClassBatch",
  data() {
    return {
      alert: {
        type: 'error',
        show: false,
        message: null
      },
      detail: {
        show: false
      },
      dt: {
        headers: [
          {text: 'Nama', align: 'start', sortable: true, value: 'name'},
          {text: 'Tipe', align: 'start', sortable: true, value: 'type'},
          {text: 'Class group', align: 'start', sortable: true, value: 'class_group.name'},
          {text: 'Instruktur', align: 'start', sortable: true, value: 'teacher.name'},
          {text: 'Status', align: 'start', sortable: true, value: 'status'},
          {text: 'Opsi', align: 'center', sortable: true, value: 'actions'},
        ]
      },
      type: 'xschool',
      types: [
        {text: 'X-School', value: 'xschool'},
        {text: 'Bootcamp', value: 'bootcamp'},
      ],
      itemId: null,
      search: null,
      cbs: [],
      dates: [],
      classGroup: null,
      classGroups: [],
      teacher: null,
      teachers: [],
      name: null,
      price: null,
      student_min: 0,
      student_max: 0,
      status: 'close',
      registration_start: null,
      registration_end: null,
      batch_start: null,
      batch_end: null,
      statuses: [
        {text: 'Open', value: 'open'},
        {text: 'Close', value: 'close'},
        {text: 'Running', value: 'running'},
        {text: 'Finish', value: 'finish'},
      ],
      dialog: {
        show: false,
        type: 'success',
        message: null,
        title: 'Tambah'
      },
      dialog2: {
        show: false,
        type: 'success',
        message: null
      },
      form: {
        valid: true,
        alert: {
          show: false,
          type: 'error',
          message: null
        },
        rules: {
          type: [v => !!v || 'Tipe kelas harus dipilih.'],
          classGroup: [v => !!v || 'Class group harus dipilih.'],
          teacher: [v => !!v || 'Instruktur harus dipilih.'],
          name: [v => !!v || 'Nama harus diisi.'],
          price: [
            v => !!v || 'Harga harus diisi.',
            v => !isNaN(v) ? true : 'Harga harus berupa angka.'
          ],
          student_min: [
            v => !!v || 'Jumlah siswa minimal harus diisi.',
            v => !isNaN(v) ? true : 'Jumlah siswa minimal harus berupa angka.'
          ],
          student_max: [
            v => !!v || 'Jumlah siswa maksimal harus diisi.',
            v => !isNaN(v) ? true : 'Jumlah siswa maksimal harus berupa angka.'
          ]
        }
      }
    }
  },
  methods: {
    activeStatus(mode, status) {
      if (mode === 1) {
        return status.toUpperCase();
      }
    },
    async closeDialog() {
      await this.$refs.form.reset();
      this.status = 'close';
      this.itemId = null;
      this.type = 'xschool';
      this.dialog.show = false;
    },
    async closeDialog2() {
      this.status = 'close';
      this.itemId = null;
      this.type = 'xschool';
      this.dialog2.show = false;
    },
    async deleteItem(item) {
      this.itemId = item.id;
      this.name = item.name;
      this.dialog2.show = true;
    },
    async doDelete(id) {
      this.itemId = id;
      try {
        let response = await axios.delete('/class_batch', {data: {id: id}});
        let responseData = response.data;
        if (responseData.resultStatus) {
          this.alert.type = 'error';
          this.alert.message = 'Class batch dengan nama: ' + responseData.data.name + ' berhasil dihapus.';
          this.alert.show = true;
        } else {
          console.log(responseData);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async doSubmit() {
      if (this.$refs.form.validate()) {
        let response = null;
        try {
          if (this.itemId) {
            response = await axios.put('/class_batch', {
              id: this.itemId,
              class_group: this.classGroup,
              teacher: this.teacher,
              name: this.name,
              description: this.description,
              register_start: this.registration_start,
              register_end: this.registration_end,
              batch_start: this.batch_start,
              batch_end: this.batch_end,
              price: this.price,
              status: this.status,
              type: this.type,
              student_min: this.student_min,
              student_max: this.student_max
            });
          } else {
            response = await axios.post('/class_batch', {
              class_group: this.classGroup,
              teacher: this.teacher,
              name: this.name,
              description: this.description,
              register_start: this.registration_start,
              register_end: this.registration_end,
              batch_start: this.batch_start,
              batch_end: this.batch_end,
              price: this.price,
              status: this.status,
              type: this.type,
              student_min: this.student_min,
              student_max: this.student_max
            });
          }
          let responseData = response.data;
          if (responseData.resultStatus) {
            if (this.itemId) {
              this.alert.type = 'warning';
              this.alert.message = 'Class batch dengan nama: ' + responseData.data.name + ' berhasil disimpan.';
            } else {
              this.alert.type = 'success';
              this.alert.message = 'Class batch dengan nama: ' + responseData.data.name + ' berhasil ditambahkan.';
            }
            this.alert.show = true;
            await this.closeDialog();
            await this.loadData();
          } else {
            // console.log(responseData);
            this.form.alert.type = 'error';
            this.form.alert.message = responseData.resultMsg;
            this.form.alert.show = true;
            // await this.closeDialog();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async editItem(item) {
      this.itemId = item.id;
      this.dialog.title = 'Sunting';
      this.classGroup = item.class_group.id;
      this.teacher = item.teacher.id;
      this.name = item.name;
      this.registration_start = this.moment(item.register_start).format('YYYY-MM-DD HH:mm');
      this.registration_end = this.moment(item.register_end).format('YYYY-MM-DD HH:mm');
      this.batch_start = this.moment(item.batch_start).format('YYYY-MM-DD HH:mm');
      this.batch_end = this.moment(item.batch_end).format('YYYY-MM-DD HH:mm');
      this.status = item.status;
      this.type = item.type;
      this.price = item.price;
      this.student_min = item.student_min;
      this.student_max = item.student_max;
      this.openDialog();
    },
    async loadData() {
      try {
        let cgResponse = await axios.get('/class_group');
        this.classGroups = cgResponse.data.data;
        let userResponse = await axios.get('/user', {params: {role: 4}});
        this.teachers = userResponse.data.data;
        let cbResponse = await axios.get('/class_batch');
        this.cbs = cbResponse.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    openDialog() {
      this.detail.show = false;
      this.alert.show = false;
      this.dialog.show = true;
    },
    async viewItem(item) {
      this.itemId = item.id;
      // await this.$router.push('/bo/class_batch?id' + item.id);
      try {
        let response = await axios.get('/class_batch', {params: {id: this.itemId}});
        let responseData = response.data;
        if (responseData.resultStatus) {
          this.detail.show = true;
          let data = responseData.data;
          this.itemId = data.id;
          this.name = data.name;

        } else {
          console.log(responseData);
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  async created() {
    await this.loadData();
  }
}
</script>

<style scoped>

</style>