<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col>
          <h1>Forgot password</h1>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'Login'
}
</script>