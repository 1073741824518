<template>
  <v-container>
    <v-row>
      <v-col>
        <v-dialog v-model="dialog" persistent max-width="600px" @keydown.esc="dialog=false" @click:outside="dialog=false">
          <v-form ref="form" lazy-validation v-model="valid" @submit.prevent="doSubmit">
            <v-card>
              <v-card-title>{{ dialogTitle }} kategori</v-card-title>
              <v-card-text>
                <v-select v-model="parent" label="Induk" :items="categories" item-text="name" item-value="id"></v-select>
                <v-text-field label="Nama" v-model="name" :rules="rules.name" counter="100"></v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit">Simpan</v-btn>
                <v-btn color="error" @click="cancelDialog">Batalkan</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <v-dialog v-model="dialog2" persistent max-width="600px" @keydown.esc="dialog=false" @click:outside="dialog=false">
          <v-card>
            <v-card-title>Hapus kategori</v-card-title>
            <v-card-text>
              <p>Apakah Anda yakin ingin menghapus kategori dengan nama: <strong>{{ name }}</strong>?</p>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="doDelete">Ya</v-btn>
              <v-btn color="success" @click="cancelDialog2">Tidak</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <h1>Kategori</h1>
        <v-alert dismissible :type="message.type" v-model="message.show">{{ message.message }}</v-alert>
        <v-btn color="success" @click.prevent="dialog=true, categoryId=null, dialogTitle='Tambah'" class="mb-3">Tambah</v-btn>
        <v-card>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Cari" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :search="search" :headers="dt.headers" :items-per-page="10" :items="dt.items" class="elevation-0">
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil</v-icon>
              <v-icon small @click="deleteItem(item)" color="error"> mdi-delete</v-icon>
            </template>
          </v-data-table>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "BoCategory",
  components: {},

  data() {
    return {
      dialogTitle: 'Tambah',
      categoryId: null,
      search: null,
      name: null,
      parent: null,
      dt: {
        headers: [
          {text: 'Nama', align: 'start', sortable: true, value: 'name'},
          {text: 'Induk', align: 'start', sortable: true, value: 'parent.name'},
          {text: 'Status', align: 'start', sortable: false, value: 'status'},
          {text: 'Opsi', align: 'center', sortable: false, value: 'actions'}
        ],
        items: []
      },
      message: {
        show: false,
        type: 'success',
        message: null
      },
      valid: true,
      dialog: false,
      dialog2: false,
      categories: null,
      rules: {
        name: [v => !!v || 'Nama harus diisi.']
      }
    }
  },
  methods: {
    doSubmit() {
      if (this.$refs.form.validate()) {
        // console.log(this.categoryId);
        if (this.categoryId) {
          axios.put('/category', {id: this.categoryId, parent: this.parent, name: this.name}).then(response => {
            let responseData = response.data;
            if (responseData.resultStatus) {
              this.message.show = true;
              this.message.type = 'warning';
              this.message.message = 'Kategori dengan nama ' + this.name + ' berhasil disimpan.';
              this.resetForm();
              this.dialog = false;
              this.loadData();
            } else {
              console.log(responseData.data);
              this.message.show = true;
              this.message.message = responseData.resultMsg;
            }
          }).catch(error => {
            this.resetForm();
            console.log(error);
          });
        } else {
          axios.post('/category', {parent: this.parent, name: this.name}).then(response => {
            let responseData = response.data;
            if (responseData.resultStatus) {
              this.message.show = true;
              this.message.type = 'success';
              this.message.message = 'Kategori dengan nama ' + this.name + ' berhasil ditambahkan.';
              this.resetForm();
              this.dialog = false;
              this.loadData();
            } else {
              console.log(responseData.data);
              this.message.show = true;
              this.message.message = responseData.resultMsg;
            }
          }).catch(error => {
            this.resetForm();
            console.log(error);
          });
        }
      }
    },
    async deleteItem(item) {
      let response = await axios.get('/category', {params: {id: item.id}});
      let responseData = response.data;
      if (responseData.resultStatus) {
        this.categoryId = responseData.data.id;
        this.name = responseData.data.name;
        this.parent = responseData.data.parent_id;
        this.dialog2 = true;
      }
    },
    async doDelete() {
      try {
        let response = await axios.delete('/category', {data: {id: this.categoryId}});
        if (response.data.resultStatus) {
          this.dialog2 = false;
          this.message.show = true;
          this.message.type = 'error';
          this.message.message = 'Kategori dengan nama: ' + response.data.data.name + ' berhasil dihapus.';
          await this.loadData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async editItem(item) {
      let response = await axios.get('/category', {params: {id: item.id}});
      let responseData = response.data;
      if (responseData.resultStatus) {
        this.dialogTitle = 'Sunting';
        this.categoryId = responseData.data.id;
        this.name = responseData.data.name;
        this.parent = responseData.data.parent_id;
        this.dialog = true;
      }
    },
    async loadData() {
      try {
        let response = await axios.get('/category', {params: {mode: 'parent'}});
        let response2 = await axios.get('/category');
        this.categories = response.data.data;
        this.dt.items = response2.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    resetForm() {
      // this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    cancelDialog() {
      this.resetForm();
      this.name = null;
      this.parent = null;
      this.dialog = false;
      this.categoryId = null;
    },
    cancelDialog2() {
      this.name = null;
      this.parent = null;
      this.dialog2 = false;
      this.categoryId = null;
    }
  },
  async created() {
    this.loadData();
  }
}
</script>

<style scoped>

</style>