import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login'
import ForgotPassword from "@/views/ForgotPassword";
import BoCategory from "@/views/Bo/Category"
import BoProfile from "@/views/Bo/Profile"
import BoClassGroup from "@/views/Bo/ClassGroup"
import BoUser from "@/views/Bo/User"
import BoClassBatch from "@/views/Bo/ClassBatch"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/forgot_password',
        name: 'ForgotPassword',
        component: ForgotPassword
    },
    {
        path: '/bo/category',
        name: 'BoCategory',
        component: BoCategory
    },
    {
        path: '/bo/class_group',
        name: 'BoClassGroup',
        component: BoClassGroup
    },
    {
        path: '/bo/class_batch',
        name: 'BoClassBatch',
        component: BoClassBatch
    },
    {
        path: '/bo/user',
        name: 'BoUser',
        component: BoUser
    },
    {
        path: '/bo/profile',
        name: 'BoProfile',
        component: BoProfile
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
