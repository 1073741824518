<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-dialog v-model="dialog.show" persistent max-width="1280px" @keydown.esc="closeDialog" @click:outside="closeDialog">
            <v-form ref="form" lazy-validation v-model="form.valid" @submit.prevent="doSubmit">
              <v-card>
                <v-card-title>{{ dialog.title }} user</v-card-title>
                <v-card-text>
                  <v-alert :type="formAlert.type" v-model="formAlert.show" dismissible>{{ formAlert.message }}</v-alert>
                  <v-select label="Hak akses" :rules="rules.role" :items="roles" item-text="name" item-value="id" v-model="role"></v-select>
                  <v-row dense>
                    <v-col cols="12" sm="9">
                      <v-text-field label="Nama" v-model="name" :rules="rules.name" counter="100"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-radio-group v-model="sex" row>
                        <v-radio label="Laki-laki" value="M"></v-radio>
                        <v-radio label="Perempuan" value="F"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="8">
                      <v-text-field label="Alamat" v-model="address" :rules="rules.address"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Telepon" v-model="phone" :rules="rules.phone"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Email" v-model="email" :rules="rules.email"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Password" counter="100" type="password" v-model="password" :rules="rules.password"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Konfirmasi password" type="password" v-model="passwordConfirmation" :rules="rules.passwordConfirmation"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-select label="Status" :items="statuses" item-text="text" item-value="value" v-model="status"></v-select>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" type="submit">Simpan</v-btn>
                  <v-btn color="error" @click="closeDialog">Batalkan</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
          <v-dialog v-model="dialog2.show" persistent max-width="600px" @keydown.esc="dialog2.show = false" @click:outside="dialog2.show=false">
            <v-card>
              <v-card-title>Hapus user</v-card-title>
              <v-card-text>
                <p>Apakah Anda yakin ingin menghapus user dengan nama: <strong>{{ name }}</strong>?</p>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="doDelete(uId)">Ya</v-btn>
                <v-btn color="success" @click="dialog2.show=false">Tidak</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <h1>User</h1>
          <v-alert dismissible :type="alert.type" v-model="alert.show">{{ alert.message }}</v-alert>
          <p>
            <v-btn color="success" @click="showAddDialog">Tambah</v-btn>
          </p>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Cari" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :search="search" :headers="dt.headers" :items-per-page="10" :items="users" class="elevation-0">
              <template v-slot:item.sex="{item}">
                <span>{{ item.sex === 'M' ? 'L' : 'P' }}</span>
              </template>
              <template v-slot:item.status="{item}">
                <v-chip label :color="activeStatus(item.status)">{{ item.status === 'active' ? 'Aktif' : 'Nonaktif' }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil</v-icon>
                <v-icon small @click="deleteItem(item)" color="error"> mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'BoUser',
  data() {
    return {
      dialog: {
        show: false,
        title: 'Tambah',
      },
      dialog2: {
        show: false,
        title: 'Tambah',
      },
      dt: {
        headers: [
          {text: 'Nama', align: 'start', sortable: true, value: 'name'},
          {text: 'Jenis kelamin', align: 'start', sortable: true, value: 'sex'},
          {text: 'Hak akses', align: 'start', sortable: true, value: 'roles[0].name'},
          {text: 'Email', align: 'start', sortable: true, value: 'email'},
          {text: 'Status', align: 'start', sortable: true, value: 'status'},
          {text: 'Opsi', align: 'center', sortable: false, value: 'actions'}
        ]
      },
      form: {
        valid: true
      },
      formAlert: {
        show: false,
        type: 'error',
        message: null
      },
      alert: {
        show: false,
        type: 'error',
        message: null
      },
      rules: {
        role: [v => !!v || 'Hak akses harus dipilih'],
        name: [v => !!v || 'Nama harus diisi'],
        address: [v => !!v || 'Alamat harus diisi'],
        phone: [v => !!v || 'Telepon harus diisi'],
        email: [
          v => !!v || 'Email harus diisi',
          v => /.+@.+\..+/.test(v) || 'E-mail tidak valid.'],
        password: [
          v => v ? true : this.uId ? true : 'Password harus diisi.',
          v => v && v.length >= 8 && v.length <= 100 ? true : this.uId !== null ? true : 'Password minimal 8 karakter, maksimal 100 karakter.',
        ],
        passwordConfirmation: [
          v => (v === this.password) ? true : 'Konfirmasi dan password harus sama.'
        ]
      },
      uId: null,
      search: null,
      users: [],
      roles: [],
      role: null,
      selectedRoles: [],
      name: null,
      sex: 'M',
      address: null,
      phone: null,
      email: null,
      password: null,
      passwordConfirmation: null,
      status: 'active',
      statuses: [{text: 'Aktif', value: 'active'}, {text: 'Nonaktif', value: 'inactive'}]
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    activeStatus(status) {
      if (status === 'active') {
        return 'success';
      } else {
        return 'error';
      }
    },
    closeDialog() {
      this.$refs.form.reset();
      this.dialog.show = false;
    },
    async deleteItem(item) {
      this.alert.show = false;
      this.name = item.name;
      this.uId = item.id;
      this.dialog2.show = true;
    },
    async doDelete(id) {
      try {
        let response = await axios.delete('/user', {data: {id: id}});
        let responseData = response.data;
        if (responseData.resultStatus) {
          this.dialog2.show = false;
          this.alert.show = true;
          this.alert.type = 'error';
          this.alert.message = 'User dengan nama: ' + responseData.data.name + ' berhasil dihapus.';
          await this.loadData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async doSubmit() {
      if (this.$refs.form.validate()) {
        let response = null;
        if (this.uId) {
          response = await axios.put('/user', {
            id: this.uId,
            name: this.name,
            sex: this.sex,
            address: this.address,
            phone: this.phone,
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            role: this.role,
            status: this.status
          });
        } else {
          response = await axios.post('/user', {
            name: this.name,
            sex: this.sex,
            address: this.address,
            phone: this.phone,
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            role: this.role,
            status: this.status
          });
        }
        let responseData = response.data;
        if (responseData.resultStatus) {
          this.alert.show = true;
          if (this.uId) {
            this.alert.type = 'warning';
            this.alert.message = 'User dengan nama: ' + responseData.data.name + ' berhasil disimpan.';
          } else {
            this.alert.type = 'success';
            this.alert.message = 'User dengan nama: ' + responseData.data.name + ' berhasil ditambahkan.';
          }
          this.closeDialog();
          await this.loadData();
        } else {
          this.formAlert.message = responseData.resultMsg;
          this.formAlert.show = true;
        }
      }
    },
    async editItem(item) {
      this.alert.show = false;
      this.name = item.name;
      this.address = item.address;
      this.phone = item.phone;
      this.email = item.email;
      this.sex = item.sex;
      this.role = item.roles[0].id;
      this.status = item.status;
      this.dialog.title = 'Sunting';
      this.dialog.show = true;
      this.uId = item.id;
    },
    async loadData() {
      try {
        let response = await axios.get('/user');
        let responseData = response.data;
        if (responseData.resultStatus) {
          // console.log(responseData);
          this.users = responseData.data;
        } else {
          console.log(responseData.resultMsg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    showAddDialog() {
      this.formAlert.show = false;
      this.uId = null;
      this.sex = 'M';
      this.status = 'active';
      this.alert.show = false;
      this.dialog.show = true;
    }
  },
  created() {
    this.loadData();
    axios.get('/user/get_roles', {params: {status: 'active'}}).then(response => {
      let responseData = response.data;
      this.roles = responseData.data;
      // console.log(this.roles);
    }).catch(error => {
      console.log(error);
    });
  }
}
</script>