<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col sm="4">
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="doSubmit">
            <v-card>
              <v-card-title>Masuk</v-card-title>
              <v-card-text>
                <MsgAlert :type="msgType" :message="message" v-if="showMessage"></MsgAlert>
                <v-text-field v-model="email" :rules="emailRules" label="Email" prepend-icon="mdi-account-circle"></v-text-field>
                <v-text-field :type="showPassword?'text':'password'" v-model="password" :rules="passwordRules" label="Password" prepend-icon="mdi-lock" :append-icon="showPassword?'mdi-eye':'mdi-eye-off'" @click:append="showPassword=!showPassword"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">Masuk</v-btn>
                <v-btn :to="{name:'ForgotPassword'}" color="warning">Lupa password</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import MsgAlert from "@/views/MsgAlert";

export default {
  name: 'Login',
  components: {
    MsgAlert
  },

  data() {
    return {
      showPassword: false,
      showMessage: false,
      email: null,
      msgType: 'error',
      password: null,
      message: null,
      valid: true,
      emailRules: [
        v => !!v || 'Email harus diisi.',
        v => /.+@.+\..+/.test(v) || 'E-mail tidak valid.',
      ],
      passwordRules: [
        v => !!v || 'Password harus diisi.'
      ]
    };
  },
  methods: {
    doSubmit: async function () {
      if (this.$refs.form.validate()) {
        try {
          let response = await axios.post('/user/get_token', {email: this.email, password: this.password});
          let responseData = response.data;
          if (responseData.resultStatus) {
            let token = responseData.data.token;
            await localStorage.setItem('token', token);
            await this.$store.dispatch('token', token);
            await this.$store.dispatch('user', responseData.data.user);
            await this.$router.push({name: 'Home'});
          } else {
            this.showMessage = true;
            this.message = responseData.resultMsg;
          }
        } catch (error) {
          console.log(error);
        }
        // axios.post('/user/get_token', {
        //   email: this.email,
        //   password: this.password
        // }).then(response => {
        //   let responseData = response.data;
        //   if (responseData.resultStatus) {
        //     let token = responseData.data.token;
        //     localStorage.setItem('token', token);
        //     this.$store.dispatch('user', responseData.data.user);
        //     this.$router.push({name: 'Home'});
        //   } else {
        //     this.showMessage = true;
        //     this.message = responseData.resultMsg;
        //   }
        // }).catch(error => {
        //   console.log(error);
        // });
      }
    }
  }
}
</script>