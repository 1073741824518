<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
    >
      <div class="d-flex align-center">
        <router-link :to="{name:'Home'}">
          <v-img
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
              transition="scale-transition"
              width="40"
          />
        </router-link>
        <router-link :to="{name:'Home'}">
          <v-img
              alt="Vuetify Name"
              class="shrink mt-1 hidden-sm-and-down"
              contain
              min-width="100"
              src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
              width="100"
          />
        </router-link>
      </div>

      <v-spacer></v-spacer>
      <v-btn v-if="user" :to="{name:'BoUser'}" text><span>User</span></v-btn>
      <v-btn v-if="user" :to="{name:'BoCategory'}" text><span>Kategori</span></v-btn>
      <v-btn v-if="user" :to="{name:'BoClassGroup'}" text><span>Class group</span></v-btn>
      <v-btn v-if="user" :to="{name:'BoClassBatch'}" text><span>Class batch</span></v-btn>
      <v-btn v-if="user" :to="{name:'BoProfile'}" text><span>Profil</span></v-btn>
      <v-btn v-if="user" @click.prevent="doLogout" text><span class="mr-2">Keluar</span></v-btn>
      <v-btn
          v-if="!user"
          :to="{name: 'Login'}"
          text
      >
        <span class="mr-2">Masuk</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
    <Footer v-if="footer"></Footer>
  </v-app>
</template>

<script>
import Footer from "@/views/Footer";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {
    Footer
  },

  data: () => ({
    footer: false
  }),
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    doLogout: function () {
      axios.post('/user/logout').then(response => {
        // console.log(response.data);
        localStorage.removeItem('token');
        this.$store.dispatch('user', null);
        this.$router.push({name: 'Login'})
      }).catch(error => {
        console.log(error);
      });
    }
  },
  async created() {
    try {
      const response = await axios.get('/user', {params: {mode: 'auth'}});
      let responseData = response.data;
      await this.$store.dispatch('user', responseData.data);
    } catch (error) {
      console.log(error);
    }
  }
};
</script>
