<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-dialog v-model="dialog.show" persistent max-width="1280px" @keydown.esc="closeAddDialog" @click:outside="closeAddDialog">
            <v-form ref="form" lazy-validation v-model="form.valid" @submit.prevent="doSubmit">
              <v-card>
                <v-card-title>{{ dialog.title }} class group</v-card-title>
                <v-card-text>
                  <v-alert :type="alert2.type" dismissible v-model="alert2.show">{{ alert2.message }}</v-alert>
                  <v-select label="Kategori" :rules="rules.category" v-model="category" :items="categories" item-text="name" item-value="id"></v-select>
                  <v-text-field label="Nama" :rules="rules.name" v-model="name" counter="150"></v-text-field>
                  <v-textarea v-model="description" label="Keterangan"></v-textarea>
                  <v-select label="Status" :items="statuses" v-model="status" item-text="text" item-value="value"></v-select>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" type="submit">Simpan</v-btn>
                  <v-btn color="error" @click="closeAddDialog">Batalkan</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
          <v-dialog v-model="dialog2.show" persistent max-width="600px" @keydown.esc="dialog2.show = false" @click:outside="dialog2.show=false">
            <v-card>
              <v-card-title>Hapus user</v-card-title>
              <v-card-text>
                <p>Apakah Anda yakin ingin menghapus user dengan nama: <strong>{{ name }}</strong>?</p>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="doDelete">Ya</v-btn>
                <v-btn color="success" @click="dialog2.show=false">Tidak</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <h1>Class group</h1>
          <v-alert :type="alert.type" dismissible v-model="alert.show">{{ alert.message }}</v-alert>
          <p>
            <v-btn color="success" @click.prevent="openAddDialog">Tambah</v-btn>
          </p>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Cari" single-line hide-details @keydown.esc="search=null"></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table :search="search" :items="classGroups" :headers="dt.headers" :items-per-page="10">
                <template v-slot:item.status="{item}">
                  <v-chip label :color="activeStatus(item.status)">{{ item.status === 'active' ? 'Aktif' : 'Nonaktif' }}</v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil</v-icon>
                  <v-icon small @click="deleteItem(item)" color="error"> mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "BoClassGroup",
  data() {
    return {
      dt: {
        headers: [
          {text: 'Nama', align: 'start', sortable: true, value: 'name'},
          {text: 'Kategori', align: 'start', sortable: true, value: 'category.name'},
          {text: 'Status', align: 'start', sortable: true, value: 'status'},
          {text: 'Opsi', align: 'center', sortable: true, value: 'actions'},
        ]
      },
      search: null,
      classGroups: [],
      category: null,
      name: null,
      description: null,
      cgId: null,
      categories: null,
      status: 'active',
      alert: {
        show: false,
        type: 'error',
        message: null
      },
      alert2: {
        show: false,
        type: 'error',
        message: null
      },
      statuses: [
        {text: 'Aktif', value: 'active'},
        {text: 'Nonaktif', value: 'inactive'}
      ],
      form: {
        valid: true
      },
      dialog: {
        show: false,
        title: 'Tambah'
      },
      dialog2: {
        show: false
      },
      rules: {
        name: [v => !!v || 'Nama harus diisi.'],
        category: [v => !!v || 'Kategori harus dipilih.'],
      }
    }
  },
  components: {},
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    activeStatus(status) {
      if (status === 'active') {
        return 'success';
      } else {
        return 'error';
      }
    },
    async closeAddDialog() {
      await this.$refs.form.reset();
      this.alert2.show = false;
      this.dialog.show = false;
      this.status = 'active';
    },
    async deleteItem(item) {
      this.cgId = item.id;
      this.name = item.name;
      this.dialog2.show = true;
    },
    async doDelete() {
      try {
        let response = await axios.delete('/class_group', {data: {id: this.cgId}});
        let responseData = response.data;
        if (responseData.resultStatus) {
          this.alert.type = 'error';
          this.alert.message = 'Class group dengan nama: ' + this.name + ' berhasil dihapus.';
          this.alert.show = true;
          this.dialog2.show = false;
        } else {
          console.log(responseData);
          this.alert.type = 'error';
          this.alert.message = 'Class group dengan nama: ' + this.name + ' GAGAL dihapus.';
          this.alert.show = true;
          this.dialog2.show = false;
        }
        this.cgId = null;
        this.name = null;
        await this.loadData();
      } catch (error) {
        console.log(error);
      }
    },
    async doSubmit() {
      try {
        if (this.$refs.form.validate()) {
          if (this.cgId === null) {
            let response = await axios.post('/class_group', {
              category: this.category,
              name: this.name,
              description: this.description,
              status: this.status
            });
            let responseData = response.data;
            if (responseData.resultStatus) {
              this.alert.show = true;
              this.alert.type = 'success';
              this.alert.message = 'Class group dengan nama: ' + responseData.data.name + ' berhasil ditambahkan.';
              await this.closeAddDialog();
              await this.loadData();
            } else {
              console.log(responseData);
              this.alert.show = true;
              this.alert.type = 'error';
              this.alert.message = responseData.resultMsg;
            }
          } else {
            let response = await axios.put('/class_group', {
              category: this.category,
              name: this.name,
              description: this.description,
              status: this.status,
              id: this.cgId
            });
            let responseData = response.data;
            if (responseData.resultStatus) {
              this.alert.show = true;
              this.alert.type = 'warning';
              this.alert.message = 'Class group dengan nama: ' + responseData.data.name + ' berhasil disimpan.';
              await this.closeAddDialog();
              await this.loadData();
            } else {
              console.log(responseData);
              this.alert.show = true;
              this.alert.type = 'error';
              this.alert.message = responseData.resultMsg;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async editItem(item) {
      this.cgId = item.id;
      this.name = item.name;
      this.category = item.category.id;
      this.status = item.status;
      this.description = item.description;
      this.dialog.show = true;
      this.alert.show = false;
    },
    async openAddDialog() {
      this.alert.show = false;
      this.dialog.show = true;
    },
    recursive1(result, data, parent_id) {
      data.forEach(dt => {
        if (dt.parent_id === parent_id) {
          if (parent_id === null) {
            result.push({header: dt.name});
          } else {
            result.push({name: dt.name, id: dt.id});
          }
          this.recursive1(result, data, dt.id);
        }
      });
      return result;
    },
    async loadData() {
      try {
        let response = await axios.get('/category', {params: {status: 'active'}});
        let responseData = response.data;
        let cts = null;
        if (responseData.resultStatus) {
          cts = this.recursive1([], responseData.data, null);
          this.categories = cts;
        } else {
          console.log(responseData.resultMsg);
        }
        let response2 = await axios.get('/class_group');
        let response2Data = response2.data;
        if (response2Data.resultStatus) {
          this.classGroups = response2Data.data;
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  async created() {
    await this.loadData();
  }
}
</script>