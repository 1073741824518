import Vue from 'vue'
import axios from "axios"
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import DatetimePicker from 'vuetify-datetime-picker'
import moment from 'moment'

Vue.config.productionTip = false
// axios.defaults.baseURL = 'http://localhost:3000';
axios.defaults.baseURL = 'https://api.axar.galinfo.xyz';
axios.defaults.headers.common['Authentication'] = 'Bearer: ' + localStorage.getItem('token');

Vue.use(DatetimePicker);
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
Vue.prototype.moment = moment;